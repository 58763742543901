<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto">
      <table
        style="table-layout:fixed"
        class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider uk-table-middle"
      >
        <thead class="thead-table-paranje">
          <tr>
            <th
              class="table-header uk-table-expand uk-width-small"
              rowspan="2"
            >
              <span class="uk-text-bold">Tanggal</span>
            </th>
            <th
              class="table-header uk-table-expand uk-width-small"
              rowspan="2"
            >
              <span class="uk-text-bold">Tahapan</span>
            </th>
            <th
              class="table-header uk-table-expand uk-width-small"
              rowspan="2"
            >
              <span class="uk-text-bold">Pakan (sak)</span>
            </th>
            <th
              class="table-header uk-table-expand uk-width-small"
              rowspan="2"
            >
              <span class="uk-text-bold">Pakan (kg)</span>
            </th>
            <th
              class="table-header uk-table-expand"
              rowspan="2"
            >
              <span class="uk-text-bold">Ayam Mati (ekor)</span>
            </th>
            <th
              class="table-header uk-table-expand uk-width-small"
              rowspan="2"
            >
              <span class="uk-text-bold">Culling (ekor)</span>
            </th>
            <th
              class="table-header uk-table-expand uk-width-small"
              rowspan="2"
            >
              <span class="uk-text-bold">Panen (ekor)</span>
            </th>
            <th
              class="table-header uk-table-expand uk-width-small"
              rowspan="2"
            >
              <span class="uk-text-bold">Sisa Ayam</span>
            </th>
            <th
              style="width:160px"
              class="table-header uk-table-expand uk-text-center"
              colspan="2"
            >
              <span class="uk-text-bold">Bobot Sampel (gr)</span>
            </th>
            <th
              style="width:160px"
              class="table-header uk-table-expand uk-text-center"
              colspan="2"
            >
              <span class="uk-text-bold">Daily Intake (gr)</span>
            </th>
            <th
              style="width:160px"
              class="table-header uk-table-expand uk-text-center"
              colspan="2"
            >
              <span class="uk-text-bold">Feed Intake (gr)</span>
            </th>
            <th
              style="width:160px"
              class="table-header uk-table-expand uk-text-center"
              colspan="2"
            >
              <span class="uk-text-bold">FCR</span>
            </th>
            <th
              style="width:240px"
              class="table-header uk-table-expand uk-text-center"
              colspan="3"
            >
              <span class="uk-text-bold">Cum Dep</span>
            </th>
            <th
              style="width:135px"
              class="table-header uk-text-center uk-width-small"
              rowspan="2"
            >
              <span class="uk-text-bold">Indeks Prestasi</span>
            </th>
            <!-- <th class="table-header uk-width-small uk-text-center" rowspan="2"><span class="uk-text-bold">Aksi</span></th> -->
          </tr>
          <tr>
            <td class="act-std uk-text-center">
              Act
            </td>
            <td class="act-std uk-text-center">
              Std
            </td>
            <td class="act-std uk-text-center">
              Act
            </td>
            <td class="act-std uk-text-center">
              Std
            </td>
            <td class="act-std uk-text-center">
              Act
            </td>
            <td class="act-std uk-text-center">
              Std
            </td>
            <td class="act-std uk-text-center">
              Act
            </td>
            <td class="act-std uk-text-center">
              Std
            </td>
            <td class="act-std uk-text-center">
              Act
            </td>
            <td class="act-std uk-text-center">
              Std
            </td>
            <td class="act-std uk-text-center">
              Ekor
            </td>
          </tr>
        </thead>
        <template v-if="!is_loading">
          <tbody
            v-if="cycles.data && cycles.data.length>0"
            style="border: 1px solid #e5e5e5;"
          >
            <tr
              v-for="(logs, i) in cycles.data"
              :key="i"
              style="background: #fff;"
            >
              <td>{{ logs.work_date || '-' }}</td>
              <td>{{ logs.activity_name || '-' }}</td>
              <td>{{ logs.pakan_sak }}</td>
              <td>{{ logs.group_pakan_actual }}</td>
              <td>
                <span>
                  {{ logs.group_deplesi_died }}
                </span>
                <template v-if="logs.dead_chick_picture">
                  <img
                    v-lazy="`${images}/icon/view-image.svg`"
                    alt="icon view image"
                    class="uk-margin-small-right"
                    style="cursor: pointer;"
                    @click="showDeadChickPicture(logs.dead_chick_picture)"
                  >
                </template>
              </td>
              <td>{{ logs.group_deplesi_culling }}</td>
              <td>{{ logs.harvest }}</td>
              <td>{{ logs.chic_left }}</td>
              <td :class="{ 'act-column': true, 'uk-text-center': true, 'label-red': logs.group_body_weight_actual < logs.group_body_weight_standard }">
                {{ logs.group_body_weight_actual }}
              </td>
              <td class="std-column uk-text-center">
                {{ logs.group_body_weight_standard }}
              </td>
              <td :class="{ 'act-column': true, 'uk-text-center': true, 'label-red': logs.group_daily_intake_actual < logs.group_daily_intake_standard }">
                {{ logs.group_daily_intake_actual }}
              </td>
              <td class="std-column uk-text-center">
                {{ logs.group_daily_intake_standard }}
              </td>
              <td :class="{ 'act-column': true, 'uk-text-center': true, 'label-red': logs.group_feed_intake_actual < logs.group_feed_intake_standard }">
                {{ logs.group_feed_intake_actual }}
              </td>
              <td class="std-column uk-text-center">
                {{ logs.group_feed_intake_standard }}
              </td>
              <td :class="{ 'act-column': true, 'uk-text-center': true, 'label-red': logs.group_fcr_actual < logs.group_fcr_standard }">
                {{ logs.group_fcr_actual }}
              </td>
              <td class="std-column uk-text-center">
                {{ logs.group_fcr_standard }}
              </td>
              <td
                :class="{ 'act-column': true, 'uk-text-center': true, 'label-red': logs.group_cum_dep_actual < logs.group_cum_dep_standard }"
              >
                {{ logs.group_cum_dep_actual }}
              </td>
              <td class="std-column uk-text-center">
                {{ logs.group_cum_dep_standard }}
              </td>
              <td class="std-column uk-text-center">
                {{ logs.group_cum_dep_left }}
              </td>
              <td class="std-column uk-text-center">
                {{ logs.ip_actual }}
              </td>
              <td class="uk-flex uk-flex-center">
                <img
                  v-lazy="`${images}/icon/eye.svg`"
                  alt=""
                  class="img-click"
                  @click="showDetail(logs.log_cycle_id)"
                >
              </td>
            </tr>
          </tbody>
          <empty-table
            v-else
            :colspan="21"
          />
        </template>
        <template v-else>
          <loading-table :colspan="21" />
        </template>
      </table>
    </div>
    <pagination
      :total-data="cycles.meta.total_count"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="meta.page"
      :current-limit="meta.limit"
    />

    <ModalViewDeadChick :data-image="dataImage" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import ModalViewDeadChick from './ModalViewDeadChick'
import { STATUS } from '@/utils/constant'
import { PREFIX_IMAGE } from '@/utils/constant'
import Pagination from '@/components/globals/Pagination'

export default {
  components: {
    EmptyTable,
    LoadingTable,
    Pagination,
    ModalViewDeadChick
  },
  data() {
    return {
      status: STATUS,
      images: PREFIX_IMAGE,
      dataImage: ''
    }
  },
  computed : {
    ...mapGetters({
      cycles: 'cycle/cycles',
      cage: 'activityCage/activityCageDetail',
      is_loading: 'cycle/cycles_loading',
      meta: 'cycle/meta',
      metaCycle: 'cycle/metaCycle'
    })
  },
  watch: {
    async meta() {
      await this.getCycle({ cage_id: this.$route.params.id, meta: this.meta})
    }
  },
  methods: {
    showDeadChickPicture(params) {
      this.dataImage = params
      window.UIkit.modal('#modal-view-died-chick').show()
    },
    ...mapActions({
      getCycle: 'cycle/getCycle'
    }),
    ...mapMutations({
      setMeta: 'cycle/SET_META',
      setMetaDetailHistory: 'cycle/SET_META_CYCLE'
    }),
    async changeLimit(e) {
      if (this.$route.path.includes('riwayat-siklus')) {
        this.setMetaDetailHistory({ ...this.metaCycle, page: 1, limit: e.target.value })
        await this.getCycle({
          log_cycle_id: this.$route.params.id,
          cage_id: this.cage.data.cage_id,
          meta: this.metaCycle
        })
      } else {
        this.setMeta({
          ...this.meta,
          page: 1,
          limit: e.target.value
        })
      }
    },
    async changePage(value) {
      if (this.$route.path.includes('riwayat-siklus')) {
        this.setMetaDetailHistory({ ...this.metaCycle, page: value })
        await this.getCycle({
          log_cycle_id: this.$route.params.id,
          cage_id: this.cage.data.cage_id,
          meta: this.metaCycle
        })
      } else {
        this.setMeta({
          ...this.meta,
          page: value
        })
      }
    },
    showDetail(id) {
      this.$router.push(`/admin/kegiatan-kandang/detail/${id}`)
    }
  }
}
</script>

<style scoped>
.table-header {
  vertical-align: inherit;
  min-width: 125px !important;
  width: 125px;
}
.uk-text-center {
  border-left: 1px solid #B0DFCC;
  border-right: 1px solid #B0DFCC;
}
.act-std {
  padding: 0;
  height: 24px;
}
.act-column {
  border-right: none;
  width: 50px;
}
.std-column {
  border-left: none;
  background: #F0F5F3;
  width: 50px;
}
</style>
