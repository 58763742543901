<template>
  <div
    id="modal-view-died-chick"
    class="uk-flex-top"
    uk-modal
    esc-close="false"
    bg-close="false"
    container="#container-modal-view-died-chick"
  >
    <div class="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
      <button
        class="uk-modal-close-default"
        type="button"
        uk-close
      />
      <img
        v-lazy="`${image_url}${dataImage}`"
        alt="image died chicken"
        style="width: 100%; height: auto; object-fit: cover;"
        uk-img
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  props: {
    dataImage: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      is_loading: false,
      images: PREFIX_IMAGE,
      image_url: process.env.VUE_APP_IMAGE_URL
    }
  },
  computed : {
    ...mapGetters({
    })
  }
}
</script>

<style scoped>

.uk-modal-dialog {
  width: 600px !important;
  max-width: 600px !important;
  text-align: start;
}

.uk-modal-close-default {
  color: #ffffff;
  top: -1.5%;
  right: -2%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(0 0 0 / 10%);
}

</style>
